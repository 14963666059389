@import './bootstrap.scss';


html, body, #root {
  height: 100%;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
}

.full-height-block {
  display: table;
  min-height: 100%;
  width: 100%;

  .full-height-block--cell {
    display: table-cell;
    height: 100%;
    padding-top: $navbar-brand-height + $navbar-brand-padding-y * 2;
    vertical-align: middle;
  }
}

.container-wrapper-top {
  background: url('./welding.jpg') no-repeat center center;
  background-size: cover;

  .container-overlay {
    background-color: rgba($black, 0.5);
    color: $white;

    h1 {
      font-size: 2rem;
      text-shadow: 1px 3px 3px $black;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        font-size: 3rem;
      }

      @media (min-width: map-get($grid-breakpoints, "md")) {
        font-size: 4rem;
      }
    }
  }
}

.container-wrapper-highlight {
  p {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.6px;
    padding: 50px 5%;

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      font-size: 1.625rem;
    }

    @media (min-width: map-get($grid-breakpoints, "md")) {
      font-size: 2rem;
    }
  }
}

.container-wrapper-secondary {
  background-color: $gray-200;

  .values-block {
    padding: 50px 5%;

    + .values-block {
      padding-top: 0;
    }

    p {
      font-weight: 300;

      img {
        float: left;
        padding-right: 15px;
        width: 90px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      padding: 100px 20%;

      p {
        font-size: 1.25rem;
        padding-left: 100px;
        position: relative;

        img {
          float: none;
          left: 0;
          padding-right: 0;
          position: absolute;
          top: 0;
          width: 75px;
        }
      }
    }
  }
}

.container-wrapper-contact {
  h4 {
    color: $black;
    white-space: nowrap;
  }

  p {
    font-size: 18px;
  }
}

.map {
  height: 300px;

  @media (min-height: 700px) {
    height: 400px;
  }

  @media (min-height: 800px) {
    height: 500px;
  }

  @media (min-height: 900px) {
    height: 600px;
  }
}

a {
  transition: color .2s ease-in-out;
}

.navbar-light .navbar-brand {
  font-family: Arial, sans-serif;
  font-weight: bold;

  &, &:hover, &:focus {
    color: $black;
  }

  .navbar-brand-second {
    color: $gray-500;
  }
}

.navbar-light .navbar-toggler {
  &:hover, &:focus {
    background-color: $gray-200;
  }
}

.nav-link {
  @media (max-width: map-get($grid-breakpoints, "lg") - 1px) {
    font-size: 0.875rem;
  }
}
